<template>
  <div class="main">
    <div class="page-box">
      <div class="page-title">服务外包PO签发</div>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item class="breadcrumb-active"
          ><i class="fa fa-dashboard"></i>工作台</el-breadcrumb-item
        >
        <el-breadcrumb-item class="breadcrumb-active">
          服务外包PO签发</el-breadcrumb-item
        >
      </el-breadcrumb>
    </div>
    <div class="card-box">
      <div class="screen-box">
        <div class="screen-input-box">
          <div class="title-box">PO:</div>
          <el-input v-model="PO" placeholder="PO" clearable></el-input>
        </div>
        <div class="screen-input-box">
          <div class="title-box">项目部:</div>
          <el-select
            v-model="projectID"
            filterable
            placeholder="请选择项目部"
            clearable
            class="card-select"
          >
            <el-option
              v-for="item in projectList"
              :key="item.ProjectID"
              :label="item.ProjectName"
              :value="item.ProjectID"
            >
            </el-option>
          </el-select>
        </div>
        <div class="screen-input-box">
          <div class="title-box">岗位职务:</div>
          <el-select
            v-model="positionID"
            filterable
            placeholder="请选择项目部"
            clearable
            class="card-select"
          >
            <el-option
              v-for="item in positionList"
              :key="item.ID"
              :label="item.PositionsCHS"
              :value="item.ID"
            >
            </el-option>
          </el-select>
        </div>
        <div class="screen-input-box">
          <div class="title-box">审批状态:</div>
          <el-select
            v-model="auditStatus"
            class="card-select"
            placeholder="请选择审批状态"
            filterable
            clearable
          >
            <el-option
              v-for="item in options2"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="screen-input-box">
          <div class="title-box">外包单位:</div>
          <el-select
            v-model="supplier"
            class="card-select"
            placeholder="外包单位"
            filterable
            clearable
          >
            <el-option
              v-for="item in purchaseOrderApplicationList"
              :key="item.KeyID"
              :label="`${item.SupplierName}(${item.AbbreviationName})`"
              :value="item.KeyID"
            >
            </el-option>
          </el-select>
        </div>
        <div class="screen-input-box">
          <div class="title-box">甲方:</div>
          <el-select
            v-model="firstParty"
            class="card-select"
            placeholder="甲方"
            filterable
            clearable
          >
            <el-option
              v-for="item in GovernmentList"
              :key="item.ID"
              :label="item.NameinChinese"
              :value="item.ID"
            >
            </el-option>
          </el-select>
        </div>
        <button class="btn-pre" @click="getPurchaseOrderApplication(1)">
          <i class="fa fa-search"></i>查询
        </button>
        <button class="btn-add btn-pre" @click="newBuilt" v-if="powers.add">
          <i class="fa ion-plus-round"></i>新建
        </button>
      </div>
      <div class="table-box">
        <el-table
          :data="tableData"
          height="500"
          border
          :row-class-name="tableRowClassName"
          tooltip-effect="light"
          :header-cell-style="{ color: '#f5f5f5', background: '#23527c' }"
          :empty-text="loading ? '加载中...' : '暂无数据'"
        >
          <el-table-column label="#" width="55">
            <template slot-scope="scope">
              <el-checkbox></el-checkbox>
            </template>
          </el-table-column>
          <el-table-column
            type="index"
            label="序号"
            width="50"
          ></el-table-column>
          <el-table-column prop="PONO" label="po" width="120" />
          <el-table-column prop="SupplierKeyID" label="外包单位" width="120">
            <template slot-scope="scope">
              {{
                scope.row.SupplierKeyID
                  | SupplierKeyFormater(purchaseOrderApplicationList)
              }}
            </template>
          </el-table-column>
          <el-table-column prop="FirstPartyName" label="甲方" width="120" />
          <el-table-column prop="ProjectName" label="项目部" width="70">
          </el-table-column>
          <el-table-column prop="JobProfessionName" label="岗位职务" width="70">
            <template slot-scope="scope">
              <span v-if="scope.row.Details[0]">
                {{ scope.row.Details[0].JobProfessionName }}
              </span>
            </template>
          </el-table-column>

          <el-table-column prop="AuditStatus" label="审批状态" width="130">
            <template slot-scope="scope">
              <div
                v-if="scope.row.AuditStatus === null"
                class="label-primary approval"
              >
                待发布
              </div>
              <div
                v-if="scope.row.AuditStatus === 0"
                class="label-primary approval"
              >
                待审核
              </div>
              <div
                v-if="scope.row.AuditStatus === 1"
                class="label-success approval"
              >
                审核通过
              </div>
              <div
                v-if="scope.row.AuditStatus === 2"
                class="label-info approval"
              >
                审核中
              </div>
              <div
                v-if="scope.row.AuditStatus === 3"
                class="label-warning approval"
              >
                审核未通过
              </div>
              <div
                v-if="scope.row.AuditStatus === 4"
                class="label-danger approval"
              >
                驳回
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="150">
            <template slot-scope="scope">
              <i @click="query(scope.row)" class="fa fa-search"></i>
              <i
                class="fa fa-edit"
                @click="edit(scope.row)"
                v-if="powers.update"
              ></i>
              <i
                class="fa fa-trash"
                v-if="powers.delete"
                @click="deltePurchaseOrderApplication(scope.row)"
              ></i>
              <i
                class="fa fa-image"
                @click="image(scope.row)"
                v-if="powers.upload"
              ></i>
              <i
                class="fa fa-print"
                v-if="powers.export || powers.print"
                @click="goUrl(scope.row)"
              ></i>
            </template>
          </el-table-column>
        </el-table>

        <el-pagination
          @size-change="getPurchaseOrderApplication(1)"
          @current-change="getPurchaseOrderApplication"
          :current-page.sync="page"
          :page-size.sync="size"
          :page-sizes="[10, 20, 50, 100]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      title="当地岗位服务外包订单"
      :visible.sync="dialogVisible"
      width="99vw"
      :before-close="handleClose"
      top="1vh"
    >
      <div class="dialog-main">
        <Ljsoos
          ref="ljsoos"
          @update="getPurchaseOrderApplication"
          @close="handleClose"
        ></Ljsoos>
      </div>
      <div class="dialog-footer">
        <el-button class="pop-close" @click="handleClose">关闭</el-button>
        <el-button
          class="pop-save"
          :disabled="disabled || EditState == 2"
          @click="save"
          >保存</el-button
        >
        <el-button
          class="pop-release"
          :disabled="disabled || EditState == 2"
          @click="release"
          >发布</el-button
        >
      </div>
    </el-dialog>

    <el-dialog
      title="附件上传"
      :visible.sync="dialogVisible2"
      width="600px"
      :before-close="handleClose2"
      top="50px"
      class="dialog"
    >
      <el-table
        :data="tableData2"
        border
        class="tableData2"
        tooltip-effect="light"
        empty-text="暂无附件"
        :header-cell-style="{
          color: '#333',
          background: '#f9f9f9',
          'font-weight': 'bolder',
        }"
      >
        <el-table-column type="index" label="序号" width="40" />
        <el-table-column prop="Name" label="文件名称" width="150" />
        <el-table-column label="操作" width="80">
          <template slot-scope="scope">
            <i
              class="fa fa-cloud-download"
              @click="download(scope.row.Url)"
            ></i>
            <i class="fa fa-trash" @click="deleteTrash(scope)"></i>
          </template>
        </el-table-column>
      </el-table>

      <div class="upload-box">
        <div class="left">
          <div v-if="name" class="name">
            <svg-icon class="icon" icon-class="glyphicon-file"></svg-icon>
            {{ name }}
          </div>
        </div>
        <el-upload
          action="#"
          class="card-upload"
          :auto-upload="true"
          :http-request="uploadAttachment"
          ref="uploadRef"
        >
          <button
            :disabled="tableData2.length >= 5"
            :class="['btn-up', { disabled: tableData2.length >= 5 }]"
          >
            <svg-icon class="icon" icon-class="file"></svg-icon>选择 ...
          </button>
        </el-upload>
      </div>
      <div class="dialog-footer">
        <el-button class="pop-close" @click="handleClose2">关闭</el-button>
        <el-button class="pop-save pop-right" @click="addAttachment"
          >保存</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  getPurchaseOrderApplication,
  getPurchaseOrderApplicationItem,
  deltePurchaseOrderApplication,
  getPurchaseOrderApplicationSuppliers,
  getGovernmentSystemDicAll,
} from "@/api/table";
import {
  getProjectAll,
  allPosition,
  allAttachment,
  uploadAttachment,
  deleteAttachment,
  addAttachment,
} from "@/api/user";
import Ljsoos from "@/components/tables/ljsoos.vue";
export default {
  filters: {
    labelFormater(id, data) {
      const item = data.find((item) => {
        return item.value === id;
      });
      return item ? item.label : null;
    },
    SupplierKeyFormater(id, data) {
      const item = data.find((item) => {
        return item.KeyID == id;
      });
      return item ? item.SupplierName : null;
    },
  },
  data() {
    return {
      tableData: [],
      tableData2: [],
      page: 1,
      size: 10,
      total: 10,
      powers: {
        add: false,
        update: false,
        delete: false,
        download: false,
        import: false,
        export: false,
        print: false,
        examine: false,
        upload: false,
        resetting: false,
      },
      options: [
        {
          value: 1,
          label: "公司",
        },
        {
          value: 2,
          label: "政府系统",
        },
      ],
      options2: [
        {
          value: 0,
          label: "待审核",
        },
        {
          value: 1,
          label: "审核通过",
        },
        {
          value: 2,
          label: "审核中",
        },
        {
          value: 3,
          label: "审核未通过",
        },
        {
          value: 4,
          label: "驳回",
        },
      ],
      dialogVisible: false,
      parameter: {
        ID: null,
        Code: null,
        NameinChinese: null,
        NameinEnglish: null,
        Remark: null,
        Enabled: false,
        TypeID: null,
      },
      loading: true,
      projectList: [],
      positionList: [],
      purchaseOrderApplicationList: [],
      GovernmentList: [],
      PO: null,
      projectID: null,
      positionID: null,
      auditStatus: null,
      supplier: null,
      firstParty: null,
      EditState: null,
      disabled: false,
      dialogVisible2: false,
      name: null,
    };
  },
  components: { Ljsoos },
  methods: {
    handleClose() {
      this.$refs.ljsoos.clearData();
      this.dialogVisible = false;
      // this.$refs.ljsoos.clearData();
      // setTimeout(() => {
      //   this.dialogVisible = false;
      // }, 100);
    },
    handleClose2() {
      this.dialogVisible2 = false;
      this.name = null;
    },
    newBuilt() {
      this.dialogVisible = true;
      this.isEdit = 1;
      setTimeout(() => {
        this.$refs.ljsoos.getNew();
      }, 100);
    },
    query(data) {
      this.disabled = true;
      this.dialogVisible = true;
      this.getPurchaseOrderApplicationItem(data.KeyID);
    },
    edit(data) {
      this.dialogVisible = true;
      this.isEdit = 2;
      this.disabled = false;
      this.getPurchaseOrderApplicationItem(data.KeyID);
    },

    allAttachment() {
      allAttachment({
        workTable: "PurchaseOrderApplication",
        workTableKey: this.KeyID,
      }).then((res) => {
        if (res.status === 200) {
          this.tableData2 = res.response ? res.response : [];
        }
      });
    },
    uploadAttachment(file) {
      const data = file.file;
      var img = data.name.substring(data.name.lastIndexOf(".") + 1);
      const suffix = img === "jpg";
      const suffix2 = img === "png";
      const suffix3 = img === "jpeg";
      const suffix4 = img === "pdf";
      const suffix5 = img === "xls";
      const suffix6 = img === "xlsx";
      if (!suffix && !suffix2 && !suffix3 && !suffix4 && !suffix5 && !suffix6) {
        this.$message.error(
          "只能上传后缀为jpg、png、jpeg、pdf、xls、xlsx的文件!"
        );
        return;
      }

      const formData = new FormData();
      formData.append("Files", data);
      uploadAttachment(formData).then((res) => {
        if (res.status === 200) {
          let FileType = 1;
          if (suffix || suffix2 || suffix3) {
            FileType = 2;
          }
          const item = {
            Name: data.name,
            Url: res.response,
            FileType,
          };
          this.name = data.name;
          this.tableData2.push(item);
        }
      });
    },
    image(data) {
      this.dialogVisible2 = true;
      this.KeyID = data.KeyID;
      this.FormID = data.ID;
      this.allAttachment();
    },
    deleteTrash(data) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        if (data.row.ID) {
          deleteAttachment({ id: data.row.ID }).then((res) => {
            if (res.status === 200) {
              this.$message.success("删除成功");
              this.tableData2.splice(data.$index, 1);
            }
          });
        } else {
          this.$message.success("删除成功");
          this.tableData2.splice(data.$index, 1);
        }
      });
    },
    addAttachment() {
      const data = {
        WorkTable: "PurchaseOrderApplication",
        WorkTableKey: this.KeyID,
        FormID: this.FormID,
        ModuleID: 337,
        Details: [],
      };

      this.tableData2.forEach((item, index) => {
        const detailItem = {
          ID: 0,
          Name: item.Name,
          Url: item.Url,
          FileType: item.FileType,
          SortID: index + 1,
        };
        if (item.ID) {
          detailItem.ID = item.ID;
        }
        data.Details.push(detailItem);
      });
      addAttachment(data).then((res) => {
        if (res.status === 200) {
          this.$message.success("保存成功");
          this.handleClose2();
        }
      });
    },
    deleteCostAllocationItem(data) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteCostAllocationItem({
          key: data.KeyID,
          costAllocation: "PurchaseOrderApplication",
        }).then((res) => {
          if (res.status === 200) {
            this.$message.success("删除成功");
            this.getCostAllocationList();
          }
        });
      });
    },
    //下载附件
    download(data) {
      location.href = process.env.VUE_APP_SERVER + "upload/" + data;
    },
    getButtonPower() {
      if (!this.$store.state.userPower) {
        this.$store.commit(
          "changeUserPower",
          JSON.parse(localStorage.getItem("userPower"))
        );
      }

      const item = this.$store.state.userPower.find((item) => {
        return item.Name === this.$route.meta.title;
      });
      const actions = item.Actions;

      actions.forEach((element) => {
        switch (element.Id) {
          case 1: //增加
            this.powers.add = true;
            break;
          case 2: //删除
            this.powers.delete = true;
            break;
          case 3: //修改
            this.powers.update = true;
            break;
          case 5: //下载
            this.powers.download = true;
            break;
          case 6: //导入
            this.powers.import = true;
            break;
          case 7: //导出
            this.powers.export = true;
            break;
          case 8: //打印
            this.powers.print = true;
            break;
          case 9: //审批
            this.powers.examine = true;
            break;
          case 10: //上传
            this.powers.upload = true;
            break;
          case 11: //重置
            this.powers.resetting = true;
            break;
        }
      });
    },
    //获取表格
    getPurchaseOrderApplication(type) {
      if (type) {
        this.page = type;
      }
      this.loading = true;
      const data = {
        page: this.page,
        size: this.size,
        PO: this.PO,
        projectID: this.projectID,
        positionID: this.positionID,
        auditStatus: this.auditStatus,
        supplier: this.supplier,
        firstParty: this.firstParty,
      };
      getPurchaseOrderApplication(data).then((res) => {
        if (res.status === 200) {
          this.tableData = res.response.data;
          this.total = res.response.dataCount;
        }
        this.loading = false;
      });
    },
    //保存
    save() {
      this.$refs.ljsoos.addCostAllocation(1);
    },
    release() {
      this.$refs.ljsoos.addCostAllocation(2);
    },
    //获取详情
    getPurchaseOrderApplicationItem(key) {
      getPurchaseOrderApplicationItem({ key: key }).then((res) => {
        if (res.status === 200) {
          this.$refs.ljsoos.geteditData(res.response);
        }
      });
    },
    //删除
    deltePurchaseOrderApplication(data) {
      let params = { key: data.KeyID };
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deltePurchaseOrderApplication(params).then((res) => {
          if (res.status === 200) {
            this.$message.success("删除成功");
            this.getPurchaseOrderApplication();
          }
        });
      });
    },
    // 打印
    goUrl(data) {
      this.$router.push(
        `/print?type=17&print=${this.powers.print}&export=${this.powers.export}&key=${data.KeyID}&name=${data.ApplicationNumber}`
      );
    },
    tableRowClassName({ row, rowIndex }) {
      console.log("row", row.OutofPrice);
      if (row.OutofPrice === 1) {
        return "warning-row";
      }
    },
  },

  created() {
    this.getButtonPower();
    this.getPurchaseOrderApplication();
    getProjectAll().then((res) => {
      if (res.status === 200) {
        this.projectList = res.response;
      }
    });
    allPosition().then((res) => {
      if (res.status === 200) {
        this.positionList = res.response;
      }
    });
    getPurchaseOrderApplicationSuppliers().then((res) => {
      if (res.status === 200) {
        this.purchaseOrderApplicationList = res.response;
      }
    });
    getGovernmentSystemDicAll({ typeID: 1 }).then((res) => {
      if (res.status === 200) {
        console.log("res", res);
        this.GovernmentList = res.response;
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  height: 100%;
  padding: 24px;
  box-sizing: border-box;
  background-color: #f7f8fa;
  .page-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .page-title {
      font-size: 18px;
      color: #000000;
    }
    .breadcrumb-active {
      color: #303133;
      font-weight: bolder;
      cursor: pointer;
      .fa {
        margin-right: 5px;
      }
    }
  }
  .card-box {
    width: 100%;
    // min-height: calc(100% - 48px);
    // height: calc(100% - 48px);
    background: #ffffff;
    border-radius: 2px 2px 2px 2px;
    opacity: 1;
    margin-top: 24px;
    box-sizing: border-box;
    padding: 0 0 24px 0;
    .screen-box {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      position: relative;
      padding: 0 10px;
      .screen-input-box {
        margin-top: 24px;
      }
      .btn-pre {
        margin-top: 24px;
      }
      .btn-add {
        margin-top: 24px;
      }
      .el-input {
        width: 150px;
        margin-right: 24px;
      }
      .el-button {
        margin-right: 24px;
      }
      .el-select {
        width: 150px;
        margin-right: 24px;
      }
      .el-date-editor {
        margin-right: 24px;
      }
      .senior {
        position: absolute;
        width: 100%;
        top: 40px;
        left: 0;
        background: #fefefe;
        border: 1px solid #eae8e8;
        border-top-width: 1px;
        border-top-style: solid;
        border-top-color: rgb(234, 232, 232);
        box-sizing: border-box;
        z-index: 999;
        padding: 0 24px 24px 24px;
        border-top: 0;
        box-shadow: 0 7px 18px -12px #bdc0bb;
        .from-boxs {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          .from-box {
            margin-top: 24px;
            .name {
              margin-bottom: 10px;
            }
            .el-select {
              width: 150px;
            }
            .el-input {
              width: 150px;
            }
          }
        }
        .buttons {
          display: flex;
          justify-content: flex-end;
          margin-top: 24px;
        }
      }
    }
    .table-box {
      width: 100%;
      // height: calc(100% - 70px);
      .el-table {
        margin-top: 24px;
        // height: calc(100% - 56px);
        min-height: 500px;
        box-sizing: border-box;
      }
      .el-pagination {
        margin-top: 24px;
      }
      .table-color {
        color: #0076ff;
      }
      .buttons {
        display: flex;
      }
      .fa {
        margin-right: 10px;
        color: #3c8dbc;
        cursor: pointer;
      }
      .fa:hover {
        color: #72afd2;
      }
    }
  }
  .dialog-main {
    border-bottom: 1px solid #f4f4f4;
    padding: 12px 24px 0 24px;
    width: 100%;
    box-sizing: border-box;
    .line-box {
      display: flex;
      justify-content: space-between;
      width: 100%;
      .from-box {
        display: flex;
        align-items: center;
        width: 330px;
        margin-bottom: 24px;
        .name {
          width: 80px;
          font-size: 16px;
          flex-shrink: 0;
        }
        .el-cascader {
          width: 250px;
        }
        .el-input {
          width: 250px;
        }
      }
    }
  }
  .dialog-footer {
    height: 60px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .el-button {
      height: 34px;
      padding: 0 20px;
      margin-right: 12px;
      margin-left: 0;
    }
  }
}
.text-box {
  display: flex;
  color: #000000;
  .text-title {
    margin-right: 24px;
  }
  .text-name {
    color: #0076ff;
    cursor: pointer;
  }
}
.upload-box2 {
  display: flex;
  height: 34px;
  margin-top: 24px;
  .left {
    width: 100%;
    border: 1px solid #d2d6de;
    border-right: none;
    .name {
      display: flex;
      align-items: center;
      height: 34px;
      color: #555;
      font-size: 14px;
      text-indent: 2px;
    }
  }
  .btn-up {
    padding: 0;
    margin: 0;
    border: none;
    width: 90px;
    flex-shrink: 0;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #3c8dbc;
    border: 1px solid #367fa9;
    color: #fff;
    font-size: 14px;
    .icon {
      margin-right: 5px;
    }
  }
  .disabled {
    cursor: no-drop;
  }
  .btn-up:hover {
    border-color: #204d74;
    background-color: #367fa9;
  }
}
.dialog-footer2 {
  height: 60px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .el-button {
    height: 34px;
    padding: 0 20px;
    margin-right: 12px;
    margin-left: 0;
  }
}
</style>
<style lang="scss" scoped>
::v-deep .el-table {
  width: 100%;
  .el-table__header-wrapper table,
  .el-table__body-wrapper table {
    width: 100% !important;
  }
  .el-table__body,
  .el-table__footer,
  .el-table__header {
    table-layout: auto;
    .yincang {
      width: 100px !important;
      min-width: 100px !important;
      max-width: 100px !important;
      .cell {
        width: 100%;
        box-sizing: border-box !important;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: inherit !important;
        white-space: nowrap !important;
      }
    }
  }
  .el-table__header {
    .yincang {
      width: 100px !important;
      min-width: 100px !important;
      max-width: 100px !important;
      .cell {
        width: 100%;
        box-sizing: border-box !important;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: inherit !important;
        white-space: nowrap !important;
      }
    }
  }
}
.progress-box {
  height: 24px;
  display: flex;
  width: 100%;
  align-items: center;
  .el-progress {
    width: 100%;
  }
  // margin-top: 24px;
}
.upload-box {
  display: flex;
  height: 34px;
  // margin-top: 24px;
  .left {
    width: 100%;
    border: 1px solid #d2d6de;
    border-right: none;
    .name {
      display: flex;
      align-items: center;
      height: 34px;
      color: #555;
      font-size: 14px;
      text-indent: 2px;
    }
  }
  .btn-up {
    padding: 0;
    margin: 0;
    border: none;
    width: 90px;
    flex-shrink: 0;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #3c8dbc;
    border: 1px solid #367fa9;
    color: #fff;
    font-size: 14px;
    .icon {
      margin-right: 5px;
    }
  }
  .disabled {
    cursor: no-drop;
  }
  .btn-up:hover {
    border-color: #204d74;
    background-color: #367fa9;
  }
}
</style>
<style scoped>
.el-dialog__wrapper >>> .el-dialog__body {
  padding: 0;
  overflow: auto;
  border-top: 1px solid #f4f4f4;
}
.el-dialog__wrapper >>> .my-label {
  background: #f5f7fa !important;
}
.el-textarea >>> .el-textarea__inner {
  min-height: 100px !important;
}
.el-table >>> .el-table__placeholder {
  width: 23px;
}
.table-box >>> .el-table__empty-block {
  width: 100% !important;
}
.el-table >>> th.el-table__cell > .cell {
  font-weight: bolder;
  font-size: 12px;
  text-align: center;
  font-family: "Microsoft YaHei";
}

.el-table >>> th.el-table__cell {
  padding: 6px 0;
}

.el-table >>> .el-table__body .cell {
  text-align: center;
  font-size: 12px;
  color: #333;
}
.el-table >>> .el-table__cell {
  padding: 6px 0;
}
.approval {
  display: inline;
  padding: 2px 5px;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 3px;
  font-size: 12px;
}
.label-success {
  background-color: #00a65a;
}
.label-warning {
  background-color: #f39c12;
}
.fa {
  margin-right: 10px;
  color: #3c8dbc;
  cursor: pointer;
}
.fa:hover {
  color: #72afd2;
}
.screen-box >>> .el-input__inner {
  height: 34px;
  border-color: #d2d6de;
  border-radius: 0;
}
.screen-box >>> .el-input__inner:focus {
  height: 34px;
  border-color: #61a2c9;
  border-radius: 0;
}
.card-upload >>> .el-upload-list {
  display: none;
}
.dialog >>> .el-dialog__body {
  padding: 15px 15px 0 15px;
}
.tableData2 >>> .el-table__empty-block {
  width: 100% !important;
}
.card-select >>> .el-input__icon {
  line-height: 34px;
}
.downloadd {
  color: #519fff;
  cursor: pointer;
}
.el-table >>> .warning-row {
  background: #ecf5ff !important;
}
</style>
